<template>
  <div class="flight_box_two">
    <div class="flight_tag_box">
      <h3>
        {{ translatedMessage }}
      </h3>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { translateText } from '@/utils/translate';

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      translatedMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    async lang() {
      this.translatedMessage = this.data ? await translateText(this.data.Authorization_msg, this.lang) : '';
    },
    async data(newVal) {
      this.translatedMessage = this.data ? await translateText(newVal.Authorization_msg, this.lang) : '';
    },
  },
};
</script>
